import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular/standalone';
import { ComponentProps, ComponentRef } from '@ionic/core';

@Injectable({
  providedIn: 'root',
})
export default class CustomBottomSheet {
  constructor(private readonly modalController: ModalController) {}

  async open<COMPONENT_PROPS_TYPE>(
    component: ComponentRef,
    componentProps: ComponentProps<COMPONENT_PROPS_TYPE>,
    cssClass = ['custom-bottomsheet']
  ) {
    const dialog = await this.modalController.create({
      component,
      cssClass,
      componentProps,
      mode: 'ios',
    });
    await dialog.present();
    const { data } = await dialog.onDidDismiss();
    return data;
  }

  async dismiss(data?: any): Promise<void> {
    await this.modalController.dismiss(data);
  }
}
